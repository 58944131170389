<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}{{this.isBlankVue(this.type)?'当前：会计科目':'当前：账套初始化'}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="3" :sm="4" :md="4">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :lg="2" :sm="3" :md="3">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="findSearchSubjectTree"
                                           size="mini">搜索查询
                                </el-button>
                            </el-col>
                            <el-col :lg="2" :sm="3" :md="3">
                                <el-button style=" margin-top: 8px; " type="primary" @click="addSubject" size="mini">
                                    新增科目
                                </el-button>
                            </el-col>
                            <el-col :lg="2" :sm="3" :md="3">
                                <el-button style=" margin-top: 8px; " type="primary" @click="showBatchSetAssistAccounting" size="mini">
                                    批量设置辅助核算
                                </el-button>
                            </el-col>
                            <el-col :lg="2" :sm="4" :md="3" v-if="$_getActionKeyListBoolean(['/voucher']) && this.type == 'CSH'">
                                <el-link class="pull-right" style="margin-top: 11px;cursor:default" :underline="false" type="success">账套启用时间：</el-link>
                            </el-col>
                            <el-col :lg="3" :sm="6" :md="5" v-if="$_getActionKeyListBoolean(['/voucher']) && this.type == 'CSH'">
                                <el-date-picker
                                        style="margin-top: 8px;"
                                        v-model="yearMonthBook"
                                        :readonly="this.begingBook"
                                        size="mini"
                                        type="month"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择月">
                                </el-date-picker>
                            </el-col>
                            <el-col class="pull-left" :lg="2" :sm="3" :md="3" v-if="$_getActionKeyListBoolean(['/voucher']) && this.type == 'CSH'">
                                <el-button style=" margin-top: 8px; " :disabled="this.isBlankVue(this.yearMonthBook)" type="danger" @click="begingBookEvent()" size="mini">
                                    {{this.begingBook ? '暂停' : '启用'}}
                                </el-button>
                                <el-button style=" margin-top: 8px; " :disabled="this.isBlankVue(this.yearMonthBook) || !this.begingBook" type="success" @click="saveOrUpdateVoucherBook" size="mini">
                                    保存
                                </el-button>
                            </el-col>
                            <el-col :lg="6" :sm="4" :md="2" v-if="$_getActionKeyListBoolean(['/voucher']) && this.type == 'CSH'">
                                <el-button class="pull-right"
                                           :disabled="this.begingBook"
                                           style=" margin-top: 8px; " type="danger" round
                                           @click="clearVoucherBookEvent" size="mini">清空初始化列表
                                </el-button>
                                <el-button class="pull-right" round style=" margin-top: 8px; margin-right: 5px"  type="success" @click="tryBalancingFrist" size="mini">
                                    试算平衡
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                v-if="this.isBlankVue(this.type)"
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="subject_id"
                                :row-key="true"
                                align="center"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv']}"
                                :height="(this.getViewHeight() - 150)+''"
                                size='mini'
                                highlight-hover-row
                                :tree-config="{expandAll: false,reserve:true, line: true,children: 'children',transform: false}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <!--:checkbox-config="{labelField:'id_subject'}"-->
                            <template #operate="{row}">
                                <!--<vxe-button icon="vxe-icon&#45;&#45;plus" title="新增子科目" circle
                                            @click="addSubjectRowEvent(row)"></vxe-button>-->
                                <vxe-button icon="el-icon-edit-outline" title="修改当前科目" circle
                                            @click="editSubjectRowEvent(row)"></vxe-button>
                                <vxe-button icon="el-icon-delete" title="删除当前科目" circle
                                            @click="removeSubjectRowEvent(row)"></vxe-button>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>

                        <vxe-grid
                                v-if="this.type == 'CSH'"
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="SubjectIdCSH"
                                :row-key="true"
                                align="center"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv']}"
                                :height="(this.getViewHeight() - 150)+''"
                                size='mini'
                                highlight-current-row
                                :cell-class-name="cellClassName"
                                @keydown="$_onGridKeydown"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :checkbox-config="{highlight: true, range: true}"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:false,isArrow: false, isDel: true, isEnter: false, isTab: true,isEdit:true}">
                            <!-- 期初余额 -->
                            <template #id_subject_default="{row}">
                                <a :class='[row.has_child ? "" : ""]'>{{row.id_subject}}</a>
                            </template>
                            <!-- 期初余额 -->
                            <template #initial_balance_edit="{row}">
                                <vxe-input
                                        v-model="row.initial_balance" type="float" clearable
                                        @blur="computeMoney(row,'initial_balance')"
                                        :disabled="row.has_child || !begingBook"
                                        :controls="false"></vxe-input>
                            </template>
                            <!-- 本年累计借方 -->
                            <template #accumulated_debits_current_year_edit="{row}">
                                <vxe-input
                                        v-model="row.accumulated_debits_current_year" type="float" clearable
                                        @blur="computeMoney(row,'accumulated_debits_current_year')"
                                        :disabled="row.has_child || !begingBook"
                                        :controls="false"></vxe-input>
                            </template>
                            <!-- 本年累计贷方 -->
                            <template #cumulative_credit_current_year_edit="{row}">
                                <vxe-input
                                        v-model="row.cumulative_credit_current_year" type="float" clearable
                                        @blur="computeMoney(row,'cumulative_credit_current_year')"
                                        :disabled="row.has_child || !begingBook"
                                        :controls="false"></vxe-input>
                            </template>
                            <template #operate="{row}">
                                <!--<vxe-button icon="vxe-icon&#45;&#45;plus" title="新增子科目" circle
                                            @click="addSubjectRowEvent(row)"></vxe-button>-->
                                <vxe-button icon="el-icon-edit-outline" title="修改当前科目" circle
                                            @click="editSubjectRowEvent(row)"></vxe-button>
                                <vxe-button icon="el-icon-delete" title="删除当前科目" circle
                                            @click="removeSubjectRowEvent(row)"></vxe-button>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <vxe-modal width="420" :title="showSubjectEdit ? '编辑科目' : '新增科目'"
                   :lock-view="false" :mask="false" resize
                   v-model="addSubjectDialogFormVisible">
            <el-form ref="subjectForm" :model="subjectForm"
                     :rules="subjectFormRules">
                <el-form-item label="科目编号:" prop="'id_subject'">
                    <el-input-number size="mini" v-model="subjectForm.id_subject" :controls="false"
                                     :readonly="showSubjectEdit" clearable autocomplete="off"
                                     style="width: 72%;"></el-input-number>
                </el-form-item>
                <el-form-item label="科目名称:" prop="subject_name">
                    <el-input size="mini" v-model="subjectForm.subject_name" clearable autocomplete="off"
                              style="width: 72%;"></el-input>
                </el-form-item>
                <el-form-item label="余额方向:" prop="balance_direction" label-width="82px">
                    <el-radio v-model="subjectForm.balance_direction" label="借" border size="mini">借方</el-radio>
                    <el-radio v-model="subjectForm.balance_direction" label="贷" border size="mini">贷方</el-radio>
                </el-form-item>
                <el-form-item label="所属分类:" prop="father_id" label-width="82px">
                    <el-cascader
                            size="mini" v-model="subjectForm.father_id"
                            style="width: 92%;"
                            :options="tableDataMainAll"
                            :props="{checkStrictly: true ,value:'id_subject',label:'subject_name'}"
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="辅助核算:" label-width="82px">
                    <el-checkbox size="mini" v-model="subjectForm.company_name_assist_accounting" label="往来单位" border></el-checkbox>
                </el-form-item>
                <el-form-item label="科目类别:" label-width="82px">
                    <a>根据科目编码开头数字自动归类</a>
                    <br>
                    <a>1：资产、2：负债、3：权益</a>
                    <br>
                    <a>4：成本、5：损益</a>
                </el-form-item>
            </el-form>
            <div align="center">
                <el-button size="mini" @click="addSubjectDialogFormVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="saveSubject('subjectForm')">
                    确 定
                </el-button>
            </div>
        </vxe-modal>
        <el-dialog width="320px"  title="批量设置辅助核算" :visible.sync="batchSetAssistAccountingDialogFormVisible" :close-on-click-modal="false">
            <el-form >
                <el-form-item label="辅助核算:" label-width="82px">
                    <el-checkbox size="mini" v-model="batchSetAssistAccountingCompanyName" label="往来单位" border></el-checkbox>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="batchSetAssistAccountingDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="batchSetAssistAccountingEvent">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'

    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                batchSetAssistAccountingDialogFormVisible:false,//批量设置辅助核算
                batchSetAssistAccountingList:[],//批量设置辅助核算List
                batchSetAssistAccountingCompanyName:false,//批量设置辅助核算List
                type:'',
                //分类
                subjectDialogFormVisible: false,
                dialogFormVisible: false,
                addSubjectDialogFormVisible: false,
                showEdit: false,
                showSubjectEdit: false,
                subjectForm: {
                    id: '',
                    id_subject: '',
                    subject_name : '借',
                    balance_direction: '',
                    subject_key_code : '',
                    father_id : '',
                    company_name_assist_accounting :false,//往来单位 辅助核算
                    fatherName: '',
                    subject_fathe_type: '',
               },
                subjectFormRules: {
                    'id_subject': [
                        {required: true, message: "请输入：科目编码", trigger: "blur"},
                    ],
                    subject_name : [
                        {required: true, message: "请输入：科目名称", trigger: "blur"},
                    ],
               },
                formData: {//搜索筛选
                    name: null,
                    showCancel: false,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                tableColumn: [],
                tableColumnKM: [
                    {
                        type: 'checkbox',treeNode: true ,title: '全选', width: 220, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                   {
                        field:'id_subject', width: 220, title: '科目代码', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        field:'subject_name', width: 360, title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                        }
                    },
                    {
                        field:'father_id', width: 120, title: '父级ID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   }
                    , {
                        visible: false,field:'subject_type', width: 160, title: '所属分类', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   }, {
                        field:'balance_direction', width: 100, title: '余额方向', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        className: 'unEditor',
                        field:'subject_fathe_type', width: 90, title: '科目类别',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },{
                        className: 'unEditor',
                        field:'company_name_assist_accounting_name', width: 120, title: '往来单位辅助核算',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {title: '编辑', slots: {default: 'operate'}},
                    {
                        field:'subject_key_code', width: 150, title: '助记码',visible:false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                ],
                tableColumnCSH: [
                    /*{
                        type: 'checkbox',className: 'unEditor',title: '#', field:'id_subject', width: 40, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },*/
                    {
                        className: 'unEditor',title: '科目编码', field:'id_subject', width: 90, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        className: 'unEditor',field:'subject_name', title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        className: 'unEditor',field:'balance_direction', width: 100, title: '余额方向', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        field:'initial_balance', width: 150, title: '期初余额',
                        titleHelp: {message: '录入账套启用时间上一个月的月末数据'},
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots: {edit: 'initial_balance_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       },formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else {
                                return this.$XEUtils.commafy(cellValue, {digits: 2})
                           }
                       }
                   },
                    /*本年累计借方*/
                    {
                        field:'accumulated_debits_current_year', width: 150, title: '本年累计借方',
                        titleHelp: {message: '录入账套启用时间上一个月的月末数据'},
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots: {edit: 'accumulated_debits_current_year_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       },formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else {
                                return this.$XEUtils.commafy(cellValue, {digits: 2})
                           }
                       }

                   },
                   /*本年累计贷方*/
                    {
                        field:'cumulative_credit_current_year', width: 150, title: '本年累计贷方',
                        titleHelp: {message: '录入账套启用时间上一个月的月末数据'},
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots: {edit: 'cumulative_credit_current_year_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       },formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else {
                                return this.$XEUtils.commafy(cellValue, {digits: 2})
                           }
                       }

                   },
                    {
                        className: 'unEditor',
                        field:'initial_balance_current_year', width: 150, title: '年初余额',formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else {
                                return this.$XEUtils.commafy(cellValue, {digits: 2})
                           }
                       },slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {
                        className: 'unEditor',
                        field:'subject_fathe_type', width: 90, title: '科目类别',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },{
                        className: 'unEditor',
                        field:'company_name_assist_accounting_name', width: 120, title: '往来单位辅助核算',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/subject/searchHeard', this.pickerDate, this.checkedGridClassIds)
                       }
                   },
                    {className: 'unEditor',width: 120, title: '编辑', slots: {default: 'operate'}},
                ],
                tableDataMain: [],
                tableDataMainAll: [],
                filterData: {},
                sortData: {"id": "asc"},
                pickerDate: new Date(),
                activeName: 'first',
                checkedGoodsIds: [],
                checkedGridClassIds: [],
                begingBook:false,
                yearMonthBook:'',
           }
       },
        computed: {},
        methods: {
            /*
            * @param  list 数据列表
            * @param  id 后端返回的id
            **/
            getParentsById(list, id) {
                for (let i in list) {
                    if (list[i].id_subject == id) {
                        //查询到就返回该数组对象的value
                        return [list[i].id_subject]
                    }
                    if (list[i].children) {
                        let node = this.getParentsById(list[i].children, id)
                        if (node !== undefined) {
                            //查询到把父节把父节点加到数组前面
                            node.unshift(list[i].id_subject)
                            return node
                        }
                    }
                }
            },
            //启用账套
            begingBookEvent(){
                this.$axios({
                    method:'post',
                    url:'/admin/subject/begingBook',
                    params: {
                        begingBook:this.begingBook,
                        yearMonthBook:this.yearMonthBook,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                       });
                        this.searchSubjectTree();
                        this.saveOrUpdateVoucherBook();
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            //清空初始化列表
            clearVoucherBookEvent(){
                this.$XModal.confirm('将清除所有科目期初数据，请谨慎操作！?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        this.$axios({
                            method:'post',
                            url:'/admin/subject/clearVoucherBookEvent',
                       }).then(() =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            this.searchSubjectTree();
                       })
                   }
               });
           },
            //先加，后减
            //期初余额 + 本年累计借方 - 本年累计贷方 = 年初余额
            add_subVoucherBook(row){
                //row.initial_balance_current_year = 0;
                row.initial_balance_current_year = this.$XEUtils.add(row.initial_balance,row.accumulated_debits_current_year);
                row.initial_balance_current_year = this.$XEUtils.subtract(row.initial_balance_current_year,row.cumulative_credit_current_year);
           },
            //先减，后加
            //期初余额 - 本年累计借方 + 本年累计贷方 = 年初余额
            sub_addVoucherBook(row){
                /*console.log(row.initial_balance_current_year)//年初余额
                console.log(row.initial_balance)//期初余额
                console.log(row.accumulated_debits_current_year)//本年累计借方*/
                //row.initial_balance_current_year = 0;
                row.initial_balance_current_year = this.$XEUtils.subtract(row.initial_balance,row.accumulated_debits_current_year);
                row.initial_balance_current_year = this.$XEUtils.add(row.initial_balance_current_year,row.cumulative_credit_current_year);
           },
            computeMoney(row,type){
                //行数据发生改变
                if (this.$refs.xGrid.isUpdateByRow(row, type)){
                    //row.initial_balance_current_year = 0;
                    //console.log("行发生改变")
                    if (row.subject_fathe_type == '损益'){
                        if (type == 'accumulated_debits_current_year'){
                            row.cumulative_credit_current_year  = row.accumulated_debits_current_year
                       }else if (type == 'cumulative_credit_current_year'){
                            row.accumulated_debits_current_year  = row.cumulative_credit_current_year
                       }
                   }
                    if (row.balance_direction=='借') {
                        //期初余额 - 本年累计借方 + 本年累计贷方 = 年初余额
                        //initialBalance - accumulatedDebitsCurrentYear + cumulativeCreditCurrentYear = initialBalanceCurrentYear
                        //row.initial_balance_current_year = 0;
                        this.sub_addVoucherBook(row);
                   } else if (row.balance_direction=='贷') {
                        //期初余额 + 本年累计借方 - 本年累计贷方 = 年初余额
                        //initialBalance + accumulatedDebitsCurrentYear - cumulativeCreditCurrentYear = initialBalanceCurrentYear
                        //row.initial_balance_current_year = 0;
                        this.add_subVoucherBook(row);
                   }
               }
                this.totalFatherMoney(row);
           },
            totalFatherMoney(row){
                //判断是否存在父ID，是的话遍历该ID计算行相加
                //console.log("row",row)
                //console.log("row.father_id",row.father_id)
                if (!this.isBlankVue(row.father_id)){
                    let initialBalance =0 ;
                    let accumulatedDebitsCurrentYear =0 ;
                    let cumulativeCreditCurrentYear =0 ;
                    let initialBalanceCurrentYear =0 ;
                    let fList = this.tableDataMain.filter(f => f.father_id == row.father_id);
                    //console.log('fList',fList)
                    for (let i = 0; i < fList.length; i++) {
                        let f = fList[i];
                        initialBalance = this.$XEUtils.add(initialBalance,f.initial_balance);
                        accumulatedDebitsCurrentYear = this.$XEUtils.add(accumulatedDebitsCurrentYear,f.accumulated_debits_current_year);
                        cumulativeCreditCurrentYear = this.$XEUtils.add(cumulativeCreditCurrentYear,f.cumulative_credit_current_year);
                        initialBalanceCurrentYear = this.$XEUtils.add(initialBalanceCurrentYear,f.initial_balance_current_year);
                    }
                    let fatherRow  = this.tableDataMain.filter(f => f.id_subject == row.father_id)[0];
                    //console.log("fatherRow",fatherRow)
                    fatherRow.initial_balance = initialBalance;
                    fatherRow.accumulated_debits_current_year = accumulatedDebitsCurrentYear;
                    fatherRow.cumulative_credit_current_year = cumulativeCreditCurrentYear;
                    fatherRow.initial_balance_current_year = initialBalanceCurrentYear;
                    //递归，自己调自己
                    this.totalFatherMoney(fatherRow);
               }
           },

            //保存初始化
            saveOrUpdateVoucherBook(){
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method:'post',
                    url:'/admin/subject/saveOrUpdateVoucherBook',
                    data: {
                        yearMonthBook:this.yearMonthBook,
                        tableDataMain:this.tableDataMain,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.searchSubjectTree();
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    loading.close();
                    console.log(error)
               });
           },

            /**
             * 弃用实时保存，改用按钮保存
             * @param row
             */
            changeMoneyEvent (row) {//value, $event
                this.$axios({
                    method:'post',
                    url:'/admin/subject/updateVoucherBook',
                    data: {
                        yearMonthBook:this.yearMonthBook,
                        row:row,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                       });
                        //改用前端计算
                        //this.searchSubjectTree(rowIndex,field);
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            cellClassName ({row}) {
                if (row.has_child) {
                    return 'unEditor'
               }
           },
            //设置商品分类、商品同步、商品限价
            setClass() {
                let url = '/admin/subject/setClass'
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        date: this.pickerDate,
                        checkedGoodsIds: this.checkedGoodsIds,
                        classId: this.subjectForm.father_id,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                       });
                        this.subjectDialogFormVisible = false;
                        this.searchSubjectTree();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //添加子科目
            addSubjectRowEvent({row}){
                this.addSubjectDialogFormVisible = true;
                this.showSubjectEdit = false;
                this.subjectForm.id = '';
                this.subjectForm.id_subject = '';
                this.subjectForm.subject_name = '';
                this.subjectForm.subject_type = row.subject_type;
                this.subjectForm.subject_fathe_type = row.subject_fathe_type;
                this.subjectForm.balance_direction = row.balance_direction;
                this.subjectForm.subject_key_code = '';
                this.subjectForm.father_id = row.id_subject;
           },
            //批量设置辅助核算 显示
            showBatchSetAssistAccounting(){
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                if(selectRecords.length > 0 ){
                    this.batchSetAssistAccountingDialogFormVisible = true
               }else{
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }
           },
            //批量设置辅助核算 处理
            batchSetAssistAccountingEvent(){
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                for (let i = 0; i < selectRecords.length; i++) {
                    this.batchSetAssistAccountingList.push(selectRecords[i].id_subject)
               }

                this.$axios({
                    method: 'post',
                    url: '/admin/subject/batchSetAssistAccounting',
                    data:{
                        batchSetAssistAccountingList:this.batchSetAssistAccountingList,
                        batchSetAssistAccountingCompanyName:this.batchSetAssistAccountingCompanyName
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.$message({
                            showClose: true,
                            message: '批量修改成功',
                            type: 'success'
                       });
                        this.searchSubjectTree();
                        this.batchSetAssistAccountingDialogFormVisible = false;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //添加科目，任意添加
            addSubject() {
                this.addSubjectDialogFormVisible = true;
                this.showSubjectEdit = false;
                this.subjectForm.id = '';
                this.subjectForm.id_subject = '';
                this.subjectForm.subject_name = '';
                this.subjectForm.subject_type = '';
                this.subjectForm.subject_fathe_type = '';
                this.subjectForm.balance_direction = '';
                this.subjectForm.subject_key_code = '';
                this.subjectForm.father_id = '';
                //this.subjectForm.balance_direction = '借方'
           },
            saveSubject(formName) {
                let url = '';
                if (this.showSubjectEdit) {
                    url = '/admin/subject/updateSubject';
               } else {
                    url = '/admin/subject/saveSubject';
               }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.subjectForm,
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.addSubjectDialogFormVisible = false;
                                this.searchSubjectTree();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            saveSubjectClassification(formName) {
                let url = '';
                if (this.showEdit) {
                    url = '/admin/subject/updateClass';
               } else {
                    url = '/admin/subject/saveClass';
               }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.subjectClassificationForm,
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.dialogFormVisible = false;
                                this.searchSubjectTree();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },

            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.searchSubjectTree();
           },
            editSubjectRowEvent(row) {
                this.showSubjectEdit = true;
                this.addSubjectDialogFormVisible = true;
                this.subjectForm.id = row.id;
                this.subjectForm.id_subject = row.id_subject;
                this.subjectForm.subject_name = row.subject_name;
                this.subjectForm.subject_type = row.subject_type;
                this.subjectForm.subject_fathe_type = row.subject_fathe_type;
                this.subjectForm.balance_direction = row.balance_direction;
                this.subjectForm.subject_key_code = row.subject_key_code;
                this.subjectForm.company_name_assist_accounting = row.company_name_assist_accounting;
                //this.subjectForm.father_id = row.father_id;
                this.subjectForm.father_id = this.getParentsById(this.tableDataMainAll,row.father_id)
            },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除 ' + row.name + ' 分类（子分类 将变成 父类）?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/subject/deleteClass',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.searchSubjectTree();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            removeSubjectRowEvent(row) {
                this.$XModal.confirm('您确定要删除 ' + row.subject_name + '?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/subject/deleteSubject',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.searchSubjectTree();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            findSearchSubjectTree() {
                this.tablePage.currentPage = 1;
                this.searchSubjectTree();
           },
            //试算平衡，借、贷、本年累计借方、本年累计贷方
            tryBalancingFrist(){
                if (this.$_isGridDataChange(this.$refs['xGrid'])){
                    this.$message({
                        showClose: true,
                        message: '当前数据已被修改，请先保存，再试算！',
                        type: 'error'
                   });
               }else{
                    this.$axios({
                        method: 'post',
                        url: '/admin/subject/tryBalancingFrist',
                        params: {
                            yearMonthBook: this.yearMonthBook
                       }
                   }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 ) {
                            let status = 'success';
                            if (response.data.state != 'ok'){
                                status = 'error';
                           }
                            this.$XModal.alert({content: response.data.msg, status: status})
                       }
                   }).catch((error) => {
                        console.log(error)
                   });
               }
           },
            searchAllTree(){
                this.$axios({
                    method: 'post',
                    url: '/admin/subject/searchSubjectTree',
                    data: {
                        checkedGridClassIds: this.checkedGridClassIds,
                        tablePage: this.tablePage,
                        sortData: this.sortData,
                        filterData: this.filterData,
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableDataMainAll = response.data.list;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            searchSubjectTree() {
                let searchSubjectTreeURL = '/admin/subject/searchSubjectTree'
                if (this.type == 'CSH'){
                    searchSubjectTreeURL = '/admin/subject/searchSubjectTreeByCSH'
               }
                this.$axios({
                    method: 'post',
                    url: searchSubjectTreeURL,
                    data: {
                        checkedGridClassIds: this.checkedGridClassIds,
                        tablePage: this.tablePage,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        /*this.$message({
                            showClose: true,
                            message: '查询成功',
                            type: 'success'
                       });*/
                        this.tableDataMain = response.data.list;
                        this.yearMonthBook = response.data.yearMonthBook;
                        this.begingBook = response.data.begingBook;
                   }

               }).catch((error) => {
                    console.log(error)
               });
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
       },
        created() {
            this.$nextTick(() => {
                this.type = this.$route.query.type;
                this.searchAllTree();
                this.searchSubjectTree();
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                setTimeout(() => {
                    if (this.type=='CSH'){
                        this.tableColumn = this.tableColumnCSH;
                   }else{
                        this.tableColumn = this.tableColumnKM;
                   }
                    if(this.type=='CSH' && !this.begingBook){
                        this.$XModal.alert({content: '请先启用账套，才可以编辑初始化！', maskClosable: true})
                   }
               }, 1000)

           })

       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /*    .el-form-item__content .el-input{
            width: 80% !important;
       }*/
    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }

</style>
